
// @ts-ignore
    import __i18nConfig from '@next-translate-root/i18n'
// @ts-ignore
    import __appWithI18n from 'next-translate/appWithI18n'
// @ts-ignore
    
import Head from 'next/head'
import ReactGA from 'react-ga'
import dynamic from 'next/dynamic'
import React, {useEffect} from 'react'
import {ThemeProvider, GlobalStyles} from '@mui/material'

import {PATH} from 'utils/constants/paths'
import theme, {globalStyles} from 'utils/theme'

// @ts-ignore
const appGlobalStyles = <GlobalStyles styles={globalStyles} />
const Context = dynamic(() => import('utils/context').then(({AppContextProvider}) => AppContextProvider))

ReactGA.initialize('UA-195720273-1', {
  // debug: process.env.NODE_ENV !== 'production',
  testMode: process.env.NODE_ENV !== 'production'
})

const MyApp = ({Component, pageProps}) => {

  useEffect(() => {
    ReactGA.pageview(window.location.pathname)
  })
  
  const ContextProvider = ['_next/data', PATH.BANNER, PATH.DEMO].some(path => typeof window !== 'undefined' && window.location.pathname.includes(path))
    ? ({children}) => children
    : Context

  const Layout = Component.layout || (({children}) => children)
  const layoutProps = Component.layoutProps || {}

  return (
    <>
      <Head>
        <title>RightSize® - the right size for every customer for every shoe!</title>
      </Head>
      <ThemeProvider theme={theme}>
        {appGlobalStyles}
        <ContextProvider>
          <Layout {...layoutProps}>
            <Component {...pageProps} />
          </Layout>
        </ContextProvider>
      </ThemeProvider>
    </>
  )
}

const __Page_Next_Translate__ = MyApp


// @ts-ignore
    export default __appWithI18n(__Page_Next_Translate__, {
// @ts-ignore
      ...__i18nConfig,
// @ts-ignore
      isLoader: true,
// @ts-ignore
      skipInitialProps: true,
// @ts-ignore
      
// @ts-ignore
    });
// @ts-ignore
  