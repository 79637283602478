import {createTheme} from '@mui/material/styles'


declare module '@mui/material/styles' {

  interface Theme {

    fontFamily: string
    fontFamilyHeading: string
    fontSize: string
    lineHeight: number
    
    color: string
    colorDark: string
    colorAccent: string
    colorBlack: string
    colorWhite: string
    colorGray: string
    colorGrayDark: string
    colorGrayLight: string
    colorGrayAccent: string
    colorGrayAccentHover: string
    colorTransparent: string
    colorGrayTransparent: string
    colorSuccess: string
    colorSuccessAccent: string
    
    line: string
    lineThick: string

    radius: string
    radiusSharp: string

    size_0_3: string
    size_0_5: string
    size_0_9: string
    size: string
    size_1_2: string
    size_1_5: string
    size_2: string
    size_3: string

    gap: string
    gapLarge: string

    content: string
    contentPadding: string

    breakpoint: string
  }

  interface ThemeOptions {

    fontFamily: string
    fontFamilyHeading: string
    fontSize: string
    lineHeight: number
    
    color: string
    colorDark: string
    colorAccent: string
    colorBlack: string
    colorWhite: string
    colorGray: string
    colorGrayDark: string
    colorGrayLight: string
    colorGrayAccent: string
    colorGrayAccentHover: string
    colorTransparent: string
    colorGrayTransparent: string
    colorSuccess: string
    colorSuccessAccent: string
    
    line: string
    lineThick: string

    radius: string
    radiusSharp: string

    size_0_3: string
    size_0_5: string
    size_0_9: string
    size: string
    size_1_2: string
    size_1_5: string
    size_2: string
    size_3: string

    gap: string
    gapLarge: string

    content: string
    contentPadding: string

    breakpoint: string
  }
}


const theme = createTheme({

  // my original theme variables
  
  fontFamily: 'Rubik, sans-serif',
  fontFamilyHeading: 'Jost, sans-serif',
  fontSize: '87.5%',
  lineHeight: 1.5,

  color: '#f00', // red
  colorDark: '#c00',
  colorAccent: '#fcc',
  colorBlack: 'black',
  colorWhite: 'white',
  colorGray: 'gray',
  colorGrayDark: '#222',
  colorGrayLight: 'silver',
  colorGrayAccent: 'whitesmoke',
  colorGrayAccentHover: '#e8e8e8',
  colorTransparent: 'rgba(0,0,0,0)',
  colorGrayTransparent: 'rgba(192,192,192,25%)', // silver, i.e. colorGrayLight
  colorSuccess: 'green',
  colorSuccessAccent: '#d4edda',

  line: '1px',
  lineThick: '2px',

  radius: '19px',
  radiusSharp: '4px',

  size_0_3: '0.3rem',
  size_0_5: '0.5rem',
  size_0_9: '0.90rem',
  size: '1rem',
  size_1_2: '1.2rem',
  size_1_5: '1.5rem',
  size_2: '2rem',
  size_3: '3rem',

  gap: '0.5rem',
  gapLarge: '1rem',

  content: '1024px',
  contentPadding: '20px',

  breakpoint: '700px',

  // mui theme customizations

  typography: {
    fontSize: 16
    // fontFamily: 'Rubik, sans-serif',
    // h1: {
    //   fontFamily: 'Jost, sans-serif'
    // },
    // h2: {
    //   fontFamily: 'Jost, sans-serif'
    // }
  },
  palette: {
    primary: {
      main: '#ff0000'
    }
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          // borderRadius: 19,
          // textTransform: 'none'
        },
        outlined: {
          borderColor: 'silver'
        }
      }
    }
  }
})

export const globalStyles = {
  html: {
    margin: 0,
    padding: 0,
    height: '100%',
    fontSize: '87.5%',
    boxSizing: 'border-box',
    lineHeight: theme.lineHeight,
    fontFamily: theme.fontFamily
  },
  body: {
    margin: 0,
    padding: 0,
    height: '100%',
    boxSizing: 'border-box'
  },
  div: {
    margin: 0,
    padding: 0,
    boxSizing: 'border-box'
  },
  '#__next': { 
    height: '100%' 
  },
  h1: {
    fontSize: theme.size_3,
    lineHeight: theme.size_3,
    fontFamily: theme.fontFamilyHeading,
    '@media (max-width: 700px)': {
      fontSize: theme.size_2
    }
  },
  h2: {
    fontWeight: 300,
    fontSize: theme.size,
    textTransform: 'uppercase',
    fontFamily: theme.fontFamilyHeading,
  },
  h3: {
    fontFamily: theme.fontFamily
  },
  a: {
    textDecoration: 'none'
  }
}

export default theme
