// const fs = require('fs')

// const {I18N} = require('utils/constants/i18n')


module.exports = {
  // locales: fs.readdirSync('./public/locales').filter(file => file.length === 2),
  logBuild: false,
  defaultLocale: 'en',
  locales: ['en', 'lt'],
  loadLocaleFrom: (lang, ns) => import(`./public/locales/${lang}/${ns}.json`).then(m => m.default),
  pages: {
    '*': ['common'],
    '/': ['home'],
    '/docs': ['docs'],
    '/admin': ['user'],
    '/banner': ['banner'],
    '/pricing': ['account', 'pricing'],
    '/store': ['store', 'banner', 'user-signup-complete'],
    '/user/signin': ['account'],
    '/user/signup': ['account', 'user', 'user-signup', 'user-signup-complete'],
    '/user/signup/create-account': ['account', 'user', 'user-signup'],
    '/user/home/[tab]': ['account', 'user', 'user-signup-complete'],
    '/support': ['support'],
    '/support/shopify/theme-app-block': ['support', 'support-shopify-theme-app-block'],
    '/support/shopify/get-page-element': ['support', 'support-shopify-get-page-element'],
    '/client/signin': ['account'],
    '/client/signout': ['client-signout'],
    '/client/signup': ['account', 'client'],
    '/client/home/[tab]': ['user', 'client', 'docs'],
    'rgx:^/account': ['account']
  }
}
