export const PATH = {
  HOME: '/',
  DOCS: '/docs',
  STORE: '/store',
  ADMIN: '/admin',
  BANNER: '/banner',
  ACCOUNT: '/account',
  PRICING: '/pricing',
  ACCOUNT_VERIFY_EMAIL: '/account/verify-email',
  ACCOUNT_RESET_PASSWORD: '/account/reset-password',
  USER: '/user',
  USER_HOME: '/user/home',
  USER_HOME_SHOES: '/user/home/shoes',
  USER_HOME_PROFILE: '/user/home/profile',
  USER_HOME_MEASUREMENT: '/user/home/measurement',
  USER_SIGN_IN: '/user/signin',
  USER_SIGN_UP: '/user/signup',
  USER_SIGN_UP_CREATE_ACCOUNT: '/user/signup/create-account',
  CLIENT: '/client',
  CLIENT_HOME: '/client/home',
  CLIENT_HOME_DOCS: '/client/home/docs',
  CLIENT_HOME_TEAM: '/client/home/team',
  CLIENT_HOME_SIZES: '/client/home/sizes',
  CLIENT_HOME_STATS: '/client/home/stats',
  CLIENT_HOME_PROFILE: '/client/home/profile',
  CLIENT_HOME_BILLING: '/client/home/billing',
  CLIENT_SIGN_IN: '/client/signin',
  CLIENT_SIGN_UP: '/client/signup',
  CLIENT_SIGN_OUT: '/client/signout',
  LEGAL: '/legal',
  LEGAL_TERMS: '/legal/terms',
  LEGAL_PRIVACY: '/legal/privacy',
  DEMO: '/demo',
  DEMO_MEN: '/demo/men',
  DEMO_WOMEN: '/demo/women',
  SUPPORT: '/support',
  SUPPORT_SHOPIFY_THEME_APP_BLOCK: '/support/shopify/theme-app-block',
  SUPPORT_SHOPIFY_GET_PAGE_ELEMENT: '/support/shopify/get-page-element'
}

export const QUERY_KEYS = {
  MODE: 'mode',
  EMAIL: 'email',
  CLIENT_REF: 'ciu'
}

export const API_PATH = {
  CUSTOM_TOKEN: '/api/customToken',
  INVITING_CLIENT: '/api/invitingClient',
  BILLING_START_STRIPE_CHECKOUT: '/api/billing/checkoutSession',
  BILLING_START_STRIPE_BILLING_PORTAL_SESSION: '/api/billing/portalSession'
}
